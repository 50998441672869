































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import User from '@improve/common-utils/src/model/User';
import Topic from '@improve/common-utils/src/model/Topic';
import BaseTeamMemberCard
  from '@improve/common-components/src/components/widgets/BaseTeamMemberCard.vue';
import Team from '@improve/common-utils/src/model/Team';

@Component({
  name: 'TopicExperts',
  components: {
    BaseTeamMemberCard
  }
})
export default class TopicExperts extends Vue {
  @Prop({ default: null }) readonly topic!: Topic;

  @Prop({ default: () => [] }) readonly experts!: Array<User>;

  @Getter allTeamsById!: (id: string) => Team;

  userUnitName(member: User): string {
    const unit = member.unit && this.allTeamsById(member.unit);
    return (unit && unit.name) || '';
  }
}
